<script>
import {defineComponent} from '@vue/composition-api'
import {format, parseISO} from 'date-fns'
import moment from 'moment'

export default defineComponent({
  props : {
    modelValue : {
      type    : String,
      default : null
    },
    isDisabled : {
      type    : Boolean,
      default : false
    }
  },
  emits : ['update:modelValue'],
  data() {
    const [year, month, day] = this.modelValue ? this.modelValue.split('-') : [null, null, null]
    return {
      year,
      month,
      day,
    }
  },
  updated() {
    console.log('updated', this.modelValue)
  },
  created() {
    console.log('created', this.modelValue)
  },
  mounted() {
    console.log('mounted', this.modelValue)
  },
  watch : {
    modelValue(value) {
      console.log('watch', this.modelValue)
      const [year, month, day] = this.modelValue ? this.modelValue.split('-').map(x => x === 'null' ? null : Number(x)) : [null, null, null]
      this.year = year
      this.month = month
      this.day = day
    }
  },
  computed : {
    selectYears() {
      return Array.from({length : new Date().getFullYear() - 1900}, (value, index) => new Date().getFullYear() - index)
    },
    selectMonths() {
      return Array.from({length : 12}, (value, index) => 12 - index)
    },
    selectDays() {
      return Array.from({length : new Date(
        this.year ?? new Date().getFullYear(),
        this.month ?? new Date().getMonth(),
        0
      ).getDate()}, (value, index) => (new Date(this.year, this.month, 0).getDate()) - index)
    },
    computedDateFormattedMomentjs() {
      return this.modelValue ? moment(this.modelValue).format('YYYY年M月D日') : ''
    },
    computedDateFormattedDatefns() {
      return this.modelValue ? format(parseISO(this.modelValue), 'YYYY年M月D日') : ''
    },
    /*
     * Year() {
     *   return this.modelValue ? format(parseISO((new Date(this.modelValue)).toISOString()), 'yyyy') : null
     * },
     * month() {
     *   return this.modelValue ? format(parseISO((new Date(this.modelValue)).toISOString()), 'M') : null
     * },
     * day() {
     *   return this.modelValue ? format(parseISO((new Date(this.modelValue)).toISOString()), 'd') : null
     * }
     */
  },
  methods : {
    onChangeModelValue(val) {
      console.log('onChangeModelValue', val)
      this.$emit('onChangeModelValue', val)
    },
    onChangeModelTextValue(val) {
      this.$emit('onChangeModelTextValue', val)
    },
    handleInput(e) {
      const finalDate = `${this.year}-${String(this.month).padStart(2, '0')}-${String(this.day).padStart(2, '0')}`
      console.log('handleInput:', finalDate)
      this.$emit('update:modelValue', finalDate)
    }
  },
})
</script>
<template>
  <div class="date-picker">
    <v-row align="center">
      <v-col
        class="d-flex align-center"
        cols="4"
      >
        <div class="select-style">
          <select v-model="year" @change="handleInput" :disabled="isDisabled">
            <option :value="null"></option>
            <option v-for="(y, index) in selectYears" :value="y" :key="index">{{y}}</option>
          </select>
        </div>
        <span class="append ma-1 txt-black">{{ $t('MEMBER_REQUEST_YEAR_LABEL') }}</span>
      </v-col>
      <v-col
        class="d-flex align-center"
        cols="4"
      >
        <div class="select-style">
          <select v-model="month" @change="handleInput" :disabled="isDisabled">
            <option :value="null"></option>
            <option v-for="(m, index) in selectMonths" :value="m" :key="index">{{m}}</option>
          </select>
        </div>
        <span class="append ma-1 txt-black">{{ $t('MEMBER_REQUEST_MONTH_LABEL') }}</span>
      </v-col>
      <v-col
        class="d-flex align-center"
        cols="4"
      >
        <div class="select-style">
          <select v-model="day" @change="handleInput" :disabled="isDisabled">
            <option :value="null"></option>
            <option v-for="(d, index) in selectDays" :value="d" :key="index">{{d}}</option>
          </select>
        </div>
        <span class="append ma-1 txt-black">{{ $t('MEMBER_REQUEST_DAY_LABEL') }}</span>
      </v-col>

    </v-row>
  </div>
</template>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>

<style lang="scss" scoped>
  .append {
    font-size: 16px;
    font-weight: 700;
  }
</style>
<style lang="scss" scoped>
 .date-picker {
    width: 360px;
  }
  .select-style {
    width: 100%;
  }
  .txt-black {
    color: #000 !important;
  }

  @media only screen and (max-width: 767px) {
    .date-picker {
      width: 100%;
    }
  }
</style>
